import { useEffect, useState } from 'react';
import _findIndex from 'lodash/findIndex';
import { BusinessUnit } from '@zola-helpers/client/dist/es/@types';
import detectBusinessUnit from '@zola-helpers/client/dist/es/util/detectBusinessUnit';
import { jumpInPlace } from '@zola-helpers/client/dist/es/util/array';
import NAV_DATA, {
  InitNavDataEventPayload,
  SecondaryNavItem,
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
  TertiaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { getPrimaryNavData } from '~/components/navV2/NavData';
import getPromoBar from '~/components/navV2/NavData/getPromoBar';
import getAccount from '~/components/navV2/NavData/getAccount';
import { mapNavNodes } from '~/util/models/NavNode';
import { PrimaryNavMenu, PromoBarNavMenu } from './NavData/types';

const isHomeStore = typeof window !== 'undefined' && detectBusinessUnit() === BusinessUnit.STORE;

const {
  EVENTS: { INIT_NAV_DATA, NAV_LOADED },
  PRIMARY: { REGISTRY, VENDORS },
} = NAV_DATA;

type InitDataCallback = (userContext: UserContext | undefined, tab: string | undefined) => void;

const useNavData = (
  onInitData: InitDataCallback,
  userContext: UserContext | undefined,
  activeUniversalTab: string
): {
  hideAnnouncementBanner: boolean;
  disableAnnouncementBannerCollapse: boolean;
  showMessage: boolean;
  primaryNavData: { children: PrimaryNavMenu[] };
  secondaryNavData: SecondaryNavItem[];
  tertiaryNavData: SecondaryNavItem[];
  quickLinksData: SecondaryNavItem[];
  promotionalBarData: PromoBarNavMenu;
  accountNavData: PrimaryNavMenu;
  primaryLinkId: '' | PrimaryNavIdentifier | undefined;
  secondaryLinkId: string;
  tertiaryLinkId: string;
  disablePrimaryCollapse: boolean;
  emitDirectionEvent: boolean;
  disableNavCollapse: boolean;
} => {
  const [secondaryNavData, setSecondaryNavData] = useState<SecondaryNavItem[]>([]);
  const [tertiaryNavData, setTertiaryNavData] = useState<SecondaryNavItem[]>([]);
  const [quickLinksData, setQuickLinksData] = useState<SecondaryNavItem[]>([]);
  const [hideAnnouncementBanner, setHideAnnouncementBanner] = useState(false);
  const [disableAnnouncementBannerCollapse, setDisableAnnouncementBannerCollapse] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [disableNavCollapse, setDisableNavCollapse] = useState(false);
  const primaryNavData = getPrimaryNavData();
  // force VENDORS to be ahead of REGISTRY
  jumpInPlace(
    primaryNavData.children,
    _findIndex(primaryNavData.children, (c) => c.id === VENDORS),
    _findIndex(primaryNavData.children, (c) => c.id === REGISTRY)
  );
  const promotionalBarData = getPromoBar(userContext, activeUniversalTab);
  const accountNavData = getAccount({ userContext });
  const [primaryLinkId, setPrimaryLinkId] = useState<PrimaryNavIdentifier | ''>('');
  const [secondaryLinkId, setSecondaryLinkId] = useState<SecondaryNavIdentifier | ''>('');
  const [tertiaryLinkId, setTertiaryLinkId] = useState<TertiaryNavIdentifier | ''>('');

  const [disablePrimaryCollapse, setDisablePrimaryCollapse] = useState(false);
  const [emitDirectionEvent, setEmitDirectionEvent] = useState(false);

  useEffect(() => {
    const handleInitData = (event: CustomEvent<InitNavDataEventPayload>) => {
      const {
        detail: {
          activePrimaryLink,
          activeSecondaryLink,
          activeTertiaryLink,
          defaultTab,
          disablePrimaryNavCollapse,
          emitDirectionEvents,
          hideBanner,
          disableBannerCollapse,
          quickLinks,
          secondaryData,
          showPromo,
          tertiaryData,
          userContext: passedUserContext,
          disableNavCollapse: passedDisableNavCollapse,
        },
      } = event;

      setShowMessage(Boolean(showPromo));
      // only set if passed, otherwise calls w/o will overwrite
      if (hideBanner) {
        setHideAnnouncementBanner(Boolean(hideBanner));
      }
      if (secondaryData) {
        setSecondaryNavData(mapNavNodes(secondaryData));
      }
      if (tertiaryData) {
        setTertiaryNavData(mapNavNodes(tertiaryData));
      }
      if (quickLinks) {
        setQuickLinksData(quickLinks);
      }
      if (typeof activePrimaryLink !== 'undefined') setPrimaryLinkId(activePrimaryLink);
      if (typeof activeSecondaryLink !== 'undefined') setSecondaryLinkId(activeSecondaryLink);
      if (typeof activeTertiaryLink !== 'undefined') setTertiaryLinkId(activeTertiaryLink);
      if (typeof disableBannerCollapse !== 'undefined')
        setDisableAnnouncementBannerCollapse(disableBannerCollapse);

      if (typeof disablePrimaryNavCollapse !== 'undefined')
        setDisablePrimaryCollapse(disablePrimaryNavCollapse);
      if (typeof emitDirectionEvents !== 'undefined') setEmitDirectionEvent(emitDirectionEvents);

      if (passedDisableNavCollapse) {
        setDisableNavCollapse(passedDisableNavCollapse);
      }
      // cb could pass back all data in `detail`
      const tempDefaultTab = isHomeStore ? 'HOME_STORE' : defaultTab;
      onInitData(passedUserContext, tempDefaultTab);
    };

    window.addEventListener(INIT_NAV_DATA, handleInitData as EventListener);
    window.dispatchEvent(new CustomEvent(NAV_LOADED));

    return function cleanup() {
      window.removeEventListener(INIT_NAV_DATA, handleInitData as EventListener);
    };
  }, [onInitData]);

  useEffect(() => {
    // Fallback incase the Nav Loaded event initially fires before the host app is ready
    const onHostAppReady = () => {
      window.dispatchEvent(new CustomEvent(NAV_LOADED));
    };
    window.addEventListener('HOST_APP_READY', onHostAppReady);
    return () => {
      window.removeEventListener('HOST_APP_READY', onHostAppReady);
    };
  });

  return {
    hideAnnouncementBanner,
    disableAnnouncementBannerCollapse,
    showMessage,
    primaryNavData,
    secondaryNavData,
    promotionalBarData,
    accountNavData,
    primaryLinkId,
    secondaryLinkId,
    tertiaryLinkId,
    disablePrimaryCollapse,
    emitDirectionEvent,
    tertiaryNavData,
    quickLinksData,
    disableNavCollapse,
  };
};

export default useNavData;
