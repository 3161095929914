import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { getUserContext } from '~/selectors/user';
import { isPostAuthNav3Desktop } from '~/components/navV3/PostAuth/utils';
import { CaretV2Icon } from '@zola/zola-ui/src/components/SvgIcons/CaretV2';

import './navDropdown.less';

const NavDropdown = (props) => {
  const {
    className,
    label,
    ariaLabel,
    children,
    link,
    menuPositionX,
    eventType,
    component: Component,
    showCaret,
    variant,
    isActive,
    onClick,
    icon,
    iconNode,
  } = props;

  const [showMenu, setShowMenu] = useState(false);
  const overflowMenuNode = useRef();
  const isClickTrigger = eventType === 'click';
  const isHoverButtonTrigger = eventType === 'hoverButton';
  const HOVER_DELAY_MS = 300;
  const menuDelayTimeout = useRef(null);

  const userContext = useSelector((state) => getUserContext(state));
  const isPostAuthNav3 = isPostAuthNav3Desktop(userContext);

  const handleMenuOpen = () => {
    if (isClickTrigger) {
      return setShowMenu(true);
    }

    // Clear existing timeout before setting a new one
    clearTimeout(menuDelayTimeout.current);

    menuDelayTimeout.current = setTimeout(() => setShowMenu(true), HOVER_DELAY_MS);
    return menuDelayTimeout;
  };

  const handleMenuClose = () => {
    clearTimeout(menuDelayTimeout.current);
    return setShowMenu(false);
  };

  useEffect(() => {
    // Clear existing timeout on unmount
    return () => {
      clearTimeout(menuDelayTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO: extract this into a reusable hook
    const handleOutsideClick = (e) => {
      if (isClickTrigger && !overflowMenuNode.current.contains(e.target)) {
        handleMenuClose();
      }
    };

    if (showMenu) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const toggleDropdown = () => {
    if (!showMenu) {
      return handleMenuOpen();
    }
    return handleMenuClose();
  };

  const renderLabelComponent = () => {
    const isButton = isClickTrigger || isHoverButtonTrigger;
    const Element = isButton ? 'button' : 'a';

    let elementAttrs = {
      'aria-label': ariaLabel,
      'data-testid': 'nav-dropdown__label',
    };

    elementAttrs.className = cx('nav-dropdown__label', {
      'nav-dropdown__label--button': isButton,
      'nav-dropdown__label--link': !isButton,
      'nav-dropdown__label--showMenu': showMenu,
      'nav-dropdown__label--isSecondary': variant === 'secondary',
      'nav-dropdown__label--active': isActive,
    });

    if (isClickTrigger) {
      // eventType === 'click
      elementAttrs = {
        ...elementAttrs,
        type: 'button',
        onClick: toggleDropdown,
      };
    } else if (isHoverButtonTrigger) {
      // eventType === 'hoverButton'
      elementAttrs = {
        ...elementAttrs,
        onMouseEnter: handleMenuOpen,
      };
    } else {
      // eventType === 'hover'
      elementAttrs = {
        ...elementAttrs,
        ...link,
        ...(!link?.target && { target: '_self' }),
        onClick,
        onMouseEnter: handleMenuOpen,
      };
    }

    return (
      <Element {...elementAttrs}>
        {icon && <img src={icon} className="nav-dropdown__label--icon" alt="" />}
        {label}
        {iconNode || null}
        {showCaret && (
          <CaretV2Icon title="Caret" width={8} height={5} className="nav-dropdown__chevron" />
        )}
      </Element>
    );
  };

  return (
    <Component
      className={cx('nav-dropdown', className)}
      data-testid="nav-dropdown"
      ref={overflowMenuNode}
      onMouseLeave={isClickTrigger ? undefined : handleMenuClose}
    >
      {renderLabelComponent()}
      {showMenu && (
        <div
          data-testid="nav-dropdown__children"
          onClick={handleMenuClose}
          onKeyDown={handleMenuClose}
          className={cx('nav-dropdown__children', `nav-dropdown__children--${menuPositionX}`, {
            'nav-dropdown__children--isPostAuthNav3': isPostAuthNav3,
          })}
        >
          {children}
        </div>
      )}
    </Component>
  );
};

NavDropdown.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.string,
  showCaret: PropTypes.bool,
  eventType: PropTypes.oneOf(['click', 'hover', 'hoverButton']),
  isActive: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  link: PropTypes.shape({
    href: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
  }),
  menuPositionX: PropTypes.oneOf(['right', 'left', 'center', 'centerRight']),
  variant: PropTypes.oneOf(['primary', 'secondary']),
  onClick: PropTypes.func,
  icon: PropTypes.string,
  iconNode: PropTypes.node,
};

NavDropdown.defaultProps = {
  ariaLabel: 'More Options',
  component: 'div',
  eventType: 'click',
  menuPositionX: 'left',
  showCaret: true,
  variant: 'primary',
  isActive: false,
};

export default NavDropdown;
