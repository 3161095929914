import React from 'react';
import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';

import NavLinkColumn from '~/components/navV2/NavLinkColumn';
import NavLink from '~/components/navV2/NavLink';

import './NavPrimaryRegistryDropdown.less';

const NavPrimaryRegistryDropdown = ({ node }) => {
  const groups = _groupBy(node.children, (linkNode) => linkNode.column || 0);
  const columns = Object.keys(groups).map((g) => groups[g]); // Object.values(groups) is not supported IE11

  return (
    <div className="navPrimaryRegistryDropdown__container">
      {columns.map((column, colIndex) => (
        <NavLinkColumn
          className="navPrimaryRegistryDropdown__col"
          links={column.map((child, i) => (
            <NavLink key={i} href={child.path} variant="PrimaryNavSubLink">
              {child.title}
            </NavLink>
          ))}
          key={colIndex}
          borderLeft={colIndex === 1}
        />
      ))}
    </div>
  );
};

NavPrimaryRegistryDropdown.propTypes = {
  node: PropTypes.shape({
    children: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
        column: PropTypes.number,
      })
    ),
  }).isRequired,
};

export default NavPrimaryRegistryDropdown;
