import { UniversalTabs } from '~/reducers/nav';
import { isUserPostWedding } from '@zola-helpers/client/dist/es/util/dateUtils';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { PromoBarNavMenu } from './types';

const HELP_LINK = {
  title: 'Help',
  path: '/contact-us',
  children: [
    {
      title: 'FAQs',
      path: '/faq',
    },
    {
      title: 'Contact Us',
      path: '/contact-us',
    },
  ],
};

const FIND_COUPLE_LINK = { title: 'Find a Couple', path: '/search/wedding-registry' };

const getPromoBar = (userContext: UserContext = {}, activeTab: string): PromoBarNavMenu => {
  if (activeTab === UniversalTabs.HOME_STORE) {
    const hasRegistry = Boolean(userContext.has_registry);
    const shopLink = isUserPostWedding(userContext)
      ? { title: 'Shop', path: '/registry/shop' }
      : { title: 'Add Gifts', path: '/registry/add-gifts' };
    return {
      children: [
        {
          title: 'Registry',
          path: hasRegistry ? '/my-registry' : '/wedding-registry/benefits',
          children: hasRegistry
            ? [
                {
                  title: 'Manage Registry',
                  path: '/my-registry',
                },
                shopLink,
                {
                  title: 'Gift Tracker',
                  path: '/registry/gift-tracker/your-gifts',
                },
                FIND_COUPLE_LINK,
              ]
            : [
                {
                  title: 'Create Registry',
                  path: '/onboard/new',
                },
                {
                  title: 'Registry Benefits',
                  path: '/wedding-registry/benefits',
                },
                {
                  title: 'How It Works',
                  path: '/wedding-registry/how-it-works',
                },
                FIND_COUPLE_LINK,
              ],
        },
        HELP_LINK,
      ],
    };
  }
  return {
    children: [FIND_COUPLE_LINK, HELP_LINK],
  };
};

export default getPromoBar;
