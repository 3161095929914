import { useState, useEffect } from 'react';

import primaryNavAnimationNames from '~/components/navV2/NavDropdown/navAnimationConstants';

const { SLIDE_IN_FROM_LEFT, SLIDE_IN_FROM_RIGHT, SLIDE_OUT_TO_LEFT, SLIDE_OUT_TO_RIGHT } =
  primaryNavAnimationNames;

const useSlideHoverAnimation = ({
  prevHoverIndex,
  curHoverIndex,
  index,
}: {
  prevHoverIndex?: number;
  curHoverIndex?: number;
  index: number;
}): string | undefined | null => {
  const [animation, setAnimation] = useState<string | null>();
  useEffect(() => {
    const shouldAnimateTo = prevHoverIndex !== index && curHoverIndex === index;
    const shouldAnimateFrom = prevHoverIndex === index && curHoverIndex !== index;

    const setAnimationForActiveMenu = () => {
      // State is same - no animation.
      if (prevHoverIndex === curHoverIndex) {
        return setAnimation(null);
      }
      if (prevHoverIndex === null) {
        return setAnimation(SLIDE_IN_FROM_LEFT);
      }
      if (curHoverIndex === null) {
        return setAnimation(SLIDE_OUT_TO_RIGHT);
      }
      // Transitioning back to this nav item.
      if (shouldAnimateTo && prevHoverIndex) {
        if (prevHoverIndex < index) {
          return setAnimation(SLIDE_IN_FROM_LEFT);
        }
        if (prevHoverIndex > index) {
          return setAnimation(SLIDE_IN_FROM_RIGHT);
        }
      } else {
        // Transitioning away from this nav item.
        if (curHoverIndex !== undefined && index < curHoverIndex) {
          return setAnimation(SLIDE_OUT_TO_RIGHT);
        }
        if (curHoverIndex !== undefined && index > curHoverIndex) {
          return setAnimation(SLIDE_OUT_TO_LEFT);
        }
      }
      return null;
    };
    if (shouldAnimateTo || shouldAnimateFrom) {
      setAnimationForActiveMenu();
    }
  }, [setAnimation, curHoverIndex, index, prevHoverIndex]);

  return animation;
};

export default useSlideHoverAnimation;
